<template>
	<div>
        <v-row v-if="!loading && !permissions.can_view">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <p class="text-h5 mb-4">Promoter Payroll Management</p>
                <p class="text-title mb-0">You do not have permission to view all promoter payrolls</p>
            </v-col>
        </v-row>
        <template v-if="permissions.can_view">
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-sheet color="white" elevation="0" class="pa-0">
                        <v-row>
                            <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                <display-label label="Start Date" :text="object.start_date"></display-label>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                <display-label label="End Date" :text="object.end_date"></display-label>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                <display-label label="No. of Promoters" :text="object.quantity.toString()"></display-label>
                            </v-col>
                        </v-row>
                    </v-sheet>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-sheet color="white" elevation="0" class="pa-0">
                        <v-row>
                            <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                <display-label label="Total Gross" :text="object.gross.toString()"></display-label>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                <display-label label="Total Reimbursement" :text="object.reimbursement.toString()"></display-label>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                <display-label label="Total Additional" :text="object.additional.toString()"></display-label>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                <display-label label="Total Deduction (Before CPF)" :text="object.deduction_before_cpf.toString()"></display-label>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                <display-label label="Total Deduction (After CPF)" :text="object.deduction_after_cpf.toString()"></display-label>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                <display-label label="Total Amount" :text="object.total_amount.toString()"></display-label>
                            </v-col>
                        </v-row>
                    </v-sheet>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="10" lg="10" xl="10">
                    <v-btn small color="primary" class="elevation-0 mr-2" :ripple="false" @click="openUpdateDialog" v-if="permissions.can_edit && items.length > 0">Update Report</v-btn>
                    <v-menu offset-y v-if="hasMenu()">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn small color="primary" class="elevation-0" :ripple="false" v-bind="attrs" v-on="on" >Download Files</v-btn>
                        </template>
                         <v-list>
                            <v-list-item @click="openDownloadDialog('payroll')" v-if="permissions.can_download_report">
                                <v-list-item-title>Download Payroll Report</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="openGIRODownloadDialog('dbs')" v-if="hasBank('DBS')">
                                <v-list-item-title>Download DBS GIRO File</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="openGIRODownloadDialog('ocbc')" v-if="hasBank('OCBC')">
                                <v-list-item-title>Download OCBC GIRO File</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-select dense hide-details :items="cpfOptions" v-model="filters.action" @change="get"></v-select>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="text-center my-4" v-show="loading">
                    Loading data...
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" v-show="permissions.can_list">
                    <hot-table ref="table" :settings="settings" :width="getTableWidth()"></hot-table>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="text-center my-4" v-show="!loading && permissions.can_add && object.quantity === 0">
                    You have not created the payroll yet. <a class="primary--text" @click="openCreateDialog">Create</a> it now!
                </v-col>
            </v-row>
        </template>
        <create-dialog is-create ref="createDialog" @created="get"></create-dialog>
        <create-dialog ref="updateDialog" @created="get"></create-dialog>
        <download-dialog ref="downloadDialog" @downloaded="openLoadingDialog"></download-dialog>
        <giro-download-dialog ref="giroDownloadDialog" :banks="banks" @downloaded="openLoadingDialog"></giro-download-dialog>
        <simple-action-dialog :has-cancel="false" ref="loadingDialog" title="Preparing..." text="System is preparing the report now. Please be patient." confirm-text="OK"></simple-action-dialog>
	</div>
</template>


<script>

import { delay, copyArray } from '@/utils/helper'
import { HotTable } from '@handsontable/vue'
import PayrollPromoterPaymentCreateDialog from './PayrollPromoterPaymentCreateDialog'
import PayrollPromoterDownloadDialog from './PayrollPromoterDownloadDialog'
import PayrollPromoterGIRODownloadDialog from './PayrollPromoterGIRODownloadDialog'
import DisplayLabel from '../../../shared/DisplayLabel.vue'
import SimpleActionDialog from '../../../shared/SimpleActionDialog.vue'


export default {
	name: 'PayrollPromoterInfo',
	components: {
        HotTable,
        createDialog: PayrollPromoterPaymentCreateDialog,
        downloadDialog: PayrollPromoterDownloadDialog,
        giroDownloadDialog: PayrollPromoterGIRODownloadDialog,
        displayLabel: DisplayLabel,
        simpleActionDialog: SimpleActionDialog,
	},
	data () {
		return {
			loading: false,
			banks: [],
			items: [],
            cpfOptions: [
                { text: 'View All', value: 'all' },
                { text: 'View CPF only', value: 'cpf' },
                { text: 'View non-CPF only', value: 'ncpf' }
            ],
            filters: {
                action: 'all'
            },
			object: {
                start_date: '',
                end_date: '',
                quantity: '0',
                total_gross: '',
                total_reimbursement: '',
                total_additional: '',
                total_deduction_before_cpf: '',
                total_deduction_after_cpf: '',
                total_amount: ''
			},
            permissions: {
                can_view: false,
                can_add: false,
                can_edit: false,
                can_download_report: false,
                can_download_giro: false,
                can_view_items: false,
                can_edit_item: false
            },
			settings: {
                licenseKey: 'non-commercial-and-evaluation',
                data: [],
                height: 'auto',
                startRows: 250,
                rowHeaders: true,
                colHeaders: true,
                stretchH: 'all',
                minSpareRows: 1,
                columns: [
                    { data: 'name', type: 'text', readOnly: true, className: 'htLeft htMiddle' },
                    { data: 'nric', type: 'text', readOnly: true, className: 'htCenter htMiddle' },
                    { data: 'email', type: 'text', readOnly: true, className: 'htLeft htMiddle' },
                    { data: 'rest_hour', type: 'text', readOnly: true, className: 'htCenter htMiddle' },
                    { data: 'working_hours', type: 'text', readOnly: true, className: 'htCenter htMiddle' },
                    { data: 'hourly_rate', type: 'text', readOnly: true, className: 'htCenter htMiddle' },
                    { data: 'gross', type: 'text', readOnly: true, className: 'htCenter htMiddle' },
                    { data: 'reimbursement', type: 'text', readOnly: true, className: 'htCenter htMiddle' },
                    { data: 'commission', type: 'text', readOnly: true, className: 'htCenter htMiddle' },
                    { data: 'project_fee', type: 'text', readOnly: true, className: 'htCenter htMiddle' },
                    { data: 'performance', type: 'text', readOnly: true, className: 'htCenter htMiddle' },
                    { data: 'overtime', type: 'text', readOnly: true, className: 'htCenter htMiddle' },
                    { data: 'public_holiday', type: 'text', readOnly: true, className: 'htCenter htMiddle' },
                    { data: 'others', type: 'text', readOnly: true, className: 'htCenter htMiddle' },
                    { data: 'adjustment_before_cpf', type: 'text', readOnly: true, className: 'htCenter htMiddle' },
                    { data: 'adjustment_after_cpf', type: 'text', readOnly: true, className: 'htCenter htMiddle' },
                    { data: 'deduction_before_cpf', type: 'text', readOnly: true, className: 'htCenter htMiddle' },
                    { data: 'deduction_after_cpf', type: 'text', readOnly: true, className: 'htCenter htMiddle' },
                    { data: 'total_amount', type: 'text', readOnly: true, className: 'htCenter htMiddle' },
                ]
			},
		}
	},
	computed: {
        table() {
            return this.$refs.table
        },
        createDialog() {
            return this.$refs.createDialog
        },
        updateDialog() {
            return this.$refs.updateDialog
        },
        downloadDialog() {
            return this.$refs.downloadDialog
        },
        giroDownloadDialog() {
            return this.$refs.giroDownloadDialog
        },
        loadingDialog() {
            return this.$refs.loadingDialog
        }
	},
	mounted: function() {
		this.get()
	},
	methods: {
        get: function() {
			this.loading = true
			this.items = []
			this.$store.dispatch('epanel/payroll/getPayrollPromoterPayments', { id: this.$route.params['id'],  object: this.filters }).then((response) => {
                this.object = response.data.object
                this.banks = response.data.banks
                this.items = copyArray(response.data.items)
                this.settings.colHeaders = response.data.headers
                this.settings.data = response.data.items
                this.permissions = response.data.permissions
                this.updateHotTable()
                this.loading = false
			}).catch(() => {
                this.loading = false
			})
		},
        getTableWidth: function(){
            return window.innerWidth - 32
        },
        openLoadingDialog: function(){
            this.loadingDialog.open()
        },
        updateHotTable: function() {
            delay(() => {
                this.table.hotInstance.updateSettings(this.settings)
            }, 800)
        },
        openCreateDialog: function() {
            if(this.permissions.can_add){
                this.createDialog.open()
            }
        },
        openUpdateDialog: function() {
            if(this.permissions.can_add){
                this.updateDialog.open()
            }
        },
        openDownloadDialog: function(type) {
            if(this.permissions.can_download_report){
                this.downloadDialog.updateObject({ type: type, payroll: this.$route.params['id'],
                                                   action: this.filters.action })
                this.downloadDialog.open()
            }
        },
        openGIRODownloadDialog: function(type) {
            if(this.permissions.can_download_giro){
                this.giroDownloadDialog.updateObject({ type: type, payroll: this.$route.params['id'],
                                                       action: this.filters.action })
                this.giroDownloadDialog.open()
            }
        },
        hasBank: function(code) {
            return this.permissions.can_download_giro && this.banks.filter((item) => { return item.code === code}).length > 0
        },
        hasMenu: function() {
            return this.items.length > 0 && (this.permissions.can_download_giro || this.permissions.can_download_report)
        }
	}
}

</script>


<style scoped>

/deep/ .ht_clone_top.handsontable {
    z-index: 0 !important;
}

</style>