<template>
    <v-dialog persistent max-width="764" v-model="dialog">
        <v-form ref="form" action="/report/payroll/promoter/download/">
            <v-card>
                <v-card-title v-text="getTitleText()"></v-card-title>
                <v-card-text class="pb-0 my-1">
                    <input type="hidden" name="payroll" :value="object.payroll" />
                    <input type="hidden" name="type" :value="object.type" />
                    <input type="hidden" name="bank" :value="object.bank" />
                    <input type="hidden" name="submission_date" :value="object.submission_date" />
                    <input type="hidden" name="value_date" :value="object.value_date" />
                    <input type="hidden" name="batch_number" :value="object.batch_number" />
                    <input type="hidden" name="action" :value="object.action" />
                    <v-row>
						<v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
							<v-autocomplete dense label="Bank*" :items="selectedBanks" :rules="rules.bank" :error-messages="errors.bank" v-model="object.bank"></v-autocomplete>
						</v-col>
						<v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <v-menu offset-y transition="scale-transition" min-width="auto" :disabled="loading" :close-on-content-click="false" v-model="submissionDateMenu">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field dense clearable label="Submission Date*" :disabled="loading" v-bind="attrs" v-on="on" :rules="rules.submission_date" :error-messages="errors.submission_date" v-model="object.submission_date"></v-text-field>
                                </template>
                                <v-date-picker no-title color="primary" v-model="object.submission_date" @input="submissionDateMenu = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <v-menu offset-y transition="scale-transition" min-width="auto" :disabled="loading" :close-on-content-click="false" v-model="valueDateMenu">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field dense clearable label="Value Date*" :disabled="loading" v-bind="attrs" v-on="on" :rules="rules.value_date" :error-messages="errors.value_date" v-model="object.value_date"></v-text-field>
                                </template>
                                <v-date-picker no-title color="primary" v-model="object.value_date" @input="valueDateMenu = false"></v-date-picker>
                            </v-menu>
                        </v-col>
						<v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" v-if="!isCIMB()">
							<v-text-field dense label="Batch Number*" :rules="rules.batch_number" :error-messages="errors.batch_number" v-model="object.batch_number"></v-text-field>
						</v-col>
					</v-row>
                </v-card-text>
                <v-card-actions class="pl-4 pr-4">
                    <v-spacer></v-spacer>
                    <v-btn text @click="close">Later</v-btn>
                    <v-btn text color="primary" @click="download">Download</v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>


<script>

export default {
    name: 'PayrollPromoterGIRODownloadDialog',
    props: {
        banks: {
            type: Array,
            required: true,
            default: () => []
        }
    },
    data() {
        return {
            loading: false,
            dialog: false,
            submissionDateMenu: false,
            valueDateMenu: false,
            selectedBanks: [],
            object: {
                type: '',
                payroll: '',
                start_date: '',
                submission_date: '',
                value_date: '',
                batch_number: '',
                action: '',
            },
            rules: {
                bank: [
                    (value) => !!value || 'Bank is required'
                ],
                submission_date: [
                    (value) => !!value || 'Submission date is required'
                ],
                value_date: [
                    (value) => !!value || 'Value date is required'
                ],
                batch_number: [
                    (value) => {
                        if(!value) {
                            return 'Batch number is required'
                        }

                        if(!/^\d+$/.test(value)) {
                            return 'Batch number must be numeric value'
                        }

                        if(this.object.type === 'dbs' && value.length != 5) {
                            return 'Batch number is 5 characters numeric value'
                        }else if(this.object.type === 'ocbc' && value.length != 3){
                            return 'Batch number is 3 characters numeric value'
                        }

                        return true
                    }
                ]
            },
            errors: {}
        }
    },
    computed: {
        form() {
            return this.$refs.form
        }
    },
    methods: {
        open: function() {
            this.dialog = true
            this.selectedBanks = this.banks.filter((item) => { return item.code === this.object.type.toUpperCase() })
        },
        close: function() {
            this.dialog = false
        },
        updateObject: function(item) {
            this.object = item
        },
        download: function() {
            if(this.form.validate()){
                this.form.$el.submit()
                this.close()
                this.$emit('downloaded')
            }
        },
        isCIMB: function() {
            return this.type === 'CIMB'
        },
        getTitleText: function() {
            return "Download " + this.object.type.toUpperCase() + " GIRO File"
        }

    }
}

</script>