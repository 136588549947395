<template>
    <v-dialog persistent max-width="764" v-model="dialog">
        <v-card>
            <v-card-title v-text="getTitleText()"></v-card-title>
            <v-card-text class="pb-0 my-1">
                <v-row>
                    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pb-0" v-if="errors && errors.message">
                        <v-alert dense outlined type="error" class="mb-0"><span v-text="errors.message"></span></v-alert>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <v-alert dense :type="loading?'info':'warning'" class="mb-0"><span class="text-subtitle-1" v-text="getContentText()"></span></v-alert>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text :disabled="loading" @click="close">Later</v-btn>
                <v-btn text color="primary" :loading="loading" @click="save" v-text="getButtonText()"></v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>

export default {
    name: 'PayrollPromoterPaymentCreateDialog',
    props: {
        isCreate: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            dialog: false,
            loading: false,
            object: {
                type: ''
            },
            errors: {}
        }
    },
    methods: {
        open: function() {
            this.dialog = true
        },
        close: function() {
            this.dialog = false
            this.errors = {}
        },
        save: function() {
            this.loading = true
            this.errors = {}

            this.$store.dispatch(this.getActionName(), { id: this.$route.params['id'] }).then((response) => {
                this.loading = false
                if(response.data.object.total_amount){
                    this.close()
                }else{
                    this.errors = { message: response.message }
                }
                this.$emit('created')
			}).catch((errors) => {
                this.errors = errors.data?errors.data.errors:errors
                this.loading = false
			})
        },
        getActionName: function() {
            return this.isCreate?'epanel/payroll/createPayrollPromoterPayments':'epanel/payroll/updatePayrollPromoterPayments'
        },
        getTitleText: function() {
            if(this.isCreate){
                return this.loading?'Creating':'Create Payroll Report'
            }else{
                return this.loading?'Updating':'Update Payroll Report'
            }
        },
        getContentText: function() {
            if(this.isCreate){
                return this.loading?'System is creating the report now. Please be patient.':'Are you sure that you want to create the payroll report for all worked promoters?'
            }else{
                return this.loading?'System is updating the report now. Please be patient.':'Are you sure that you want to update the payroll report for all worked promoters?'
            }
        },
        getButtonText: function() {
            if(this.isCreate){
                return this.loading?'Creating':'Create Payroll Report'
            }else{
                return this.loading?'Updating':'Update Payroll Report'
            }
        }
    }
}

</script>